import React from "react";
import { Layout } from "../components/Layout";
import { Page } from "../components/Page";

export default function PageNotFound() {
  return (
    <Layout>
      <Page>Page not found.</Page>
    </Layout>
  );
}
